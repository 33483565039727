import { DBConfig } from 'ngx-indexed-db';

export const dbConfig: DBConfig = {
    name: 'mercaderies',
    version: 2,
    objectStoresMeta: [{
        store: 'pesades',
        storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [ ]
    }]
};
