<div class="container">
    <div class="row mt-3">
        <div class="col-12">
            <p>
                A continuació pots veure y editar defectes de la partida actual
            </p>
        </div>

    </div>


    <hr>

    <fieldset class="checkboxgroup">
        <label *ngFor="let defecte of defectes"><input (change)="changed()" [(ngModel)]="defecte.checked" [checked]="defecte.checked" type="checkbox"> {{defecte.nom}}</label>
    </fieldset>
</div>
