import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EntradaPartidaDetailComponent } from './components/entrada-partida-detail/entrada-partida-detail.component';
import { IdentityGuard } from '../services/identity.guard';
import { MainComponent } from './components/main/main.component';
import { EntradaDetailComponent } from '../components/mercaderies/entrada-detail/entrada-detail.component';
import { FotosComponent } from './components/fotos/fotos.component';
import { MesuraComponent } from './components/mesura/mesura.component';
import { PesadasComponent } from './components/pesadas/pesadas.component';
import { DefectesComponent } from './components/defectes/defectes.component';

const partidaRoutes: Routes = [
    {
        path: 'entrada-partida',
        component: MainComponent,
        children: [
            {
                path: '',
                component: EntradaDetailComponent
            },
            {path: ':entrada/:id', component: EntradaPartidaDetailComponent, canActivate: [IdentityGuard]},
            {path: ':entrada/:id/fotos', component: FotosComponent, canActivate: [IdentityGuard]},
            {path: ':entrada/:id/mesura', component: MesuraComponent, canActivate: [IdentityGuard]},
            {path: ':entrada/:id/defectes', component: DefectesComponent, canActivate: [IdentityGuard]},
            {path: ':entrada/:id/pesadas', component: PesadasComponent, canActivate: [IdentityGuard]},
        ],
    }
];

@NgModule({
    imports: [RouterModule.forChild(partidaRoutes)],
    exports: [RouterModule]
})
export class EntradaPartidaRoutingModule {
}
