import { Component, ElementRef, OnInit } from '@angular/core';
import { MercaderiesService } from '../../../services/mercaderies.service';
import { UserService } from '../../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { global } from '../../../services/global';
import { Pesada } from '../../models/pesada';
import { Bulto } from '../../models/bulto';

@Component({
    selector: 'app-mesura',
    templateUrl: './mesura.component.html',
    styleUrls: ['./mesura.component.scss']
})
export class MesuraComponent implements OnInit {

    public error: string;
    public entrada: any;
    public partida: any;
    public documents: any;
    public albara: any;
    public url: string;
    public pesadas: Array<Pesada>;
    public tares: Array<any>;

    // Pesada
    public pesada: Pesada;
    public bulto: Bulto;

    constructor(
        private mercaderiesService: MercaderiesService,
        private userService: UserService,
        private router: Router,
        private route: ActivatedRoute,
        private el: ElementRef
    ) {
        this.entrada = this.mercaderiesService.entrada;
        this.partida = this.mercaderiesService.partida;
        this.albara = this.mercaderiesService.albara;
        this.tares = this.mercaderiesService.tares;
        this.url = global.getUrl();
        this.bulto = new Bulto();
        this.pesada = new Pesada();
        this.pesada.entrada = this.entrada.idEntrada;
        this.pesada.partida = this.partida.idPartida;
        this.pesadas = [];
    }

    ngOnInit(): void {

    }

    resetPesada(): void {
        this.pesada = new Pesada();
        this.pesada.entrada = this.entrada.idEntrada;
        this.pesada.partida = this.partida.idPartida;
    }

    savePesada(): void {
        this.pesadas.push(this.pesada);
        this.resetPesada();
    }

    printEtiqueta(): void {
        this.mercaderiesService.printEtiquetaPesada(this.userService.getToken(), this.entrada.idEntrada, this.partida.idPartida)
            .subscribe(
                response => {
                    if (response.status === 'success') {

                    } else {
                        alert("Error al imprimir");
                    }
                },
                error => {
                    alert("Error al imprimir");
                }
            );
    }

    deleteBulto(index): void {

        // Seleccionamos el bulto en el indice del array
        const bulto: Bulto = this.pesada.bultos[index];

        // Añadimos los valores del bulto a la pesada
        this.pesada.numBultos -= bulto.bultos;
        this.pesada.bultosContiene -= bulto.bultosContiene;
        this.pesada.volum -= (bulto.fons * bulto.ample * bulto.alt);

        // Eliminamos el bulto seleccionado
        this.pesada.bultos.splice(index, 1);

        // Seleccionamos el primer input
        this.el.nativeElement.querySelector('#bultosInput').select();
    }

    addBulto(form): void {
        this.pesada.bultos.push(this.bulto);

        // Añadimos los valores del bulto a la pesada
        this.pesada.numBultos += this.bulto.bultos;
        this.pesada.bultosContiene += this.bulto.bultosContiene;
        this.pesada.volum += (this.bulto.fons * this.bulto.ample * this.bulto.alt);

        // Reinicimos el bulto
        this.bulto = new Bulto();

        // Hacemos focus sobre el primer input
        this.el.nativeElement.querySelector('#bultosInput').select();
    }

}
