import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MercaderiesService } from '../../../services/mercaderies.service';
import { UserService } from '../../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { global } from '../../../services/global';
import { Pesada } from '../../models/pesada';
import { Bulto } from '../../models/bulto';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-mesura',
    templateUrl: './mesura.component.html',
    styleUrls: ['./mesura.component.scss']
})
export class MesuraComponent implements OnInit {

    public error: string;
    public entrada: any;
    public partida: any;
    public documents: any;
    public albara: any;
    public url: string;
    public pesadas: Array<Pesada>;
    public tares: Array<any>;

    // Pesada
    public pesada: Pesada;
    public bulto: Bulto;

    @ViewChild('bultosEl') bultosEl: ElementRef;

    constructor(
        private mercaderiesService: MercaderiesService,
        private userService: UserService,
        private router: Router,
        private route: ActivatedRoute,
        private el: ElementRef,
        private dbService: NgxIndexedDBService,
        private toast: ToastrService
    ) {
        this.entrada = this.mercaderiesService.entrada;
        this.partida = this.mercaderiesService.partida;
        this.albara = this.mercaderiesService.albara;
        this.pesadas = this.mercaderiesService.pesadas;
        this.tares = this.mercaderiesService.tares;
        this.url = global.getUrl();
        this.bulto = new Bulto();
        this.pesada = this.mercaderiesService.pesada;
        this.pesada.entrada = this.entrada.idEntrada;
        this.pesada.partida = this.partida.idPartida;
        this.pesadas = [];

        this.tares.forEach((tara) => {
            tara.numero = 0;
        });
    }

    ngOnInit(): void {

    }

    resetPesada(): void {
        this.mercaderiesService.pesada = new Pesada();
        this.mercaderiesService.pesada.entrada = this.entrada.idEntrada;
        this.mercaderiesService.pesada.partida = this.partida.idPartida;
        this.pesada = this.mercaderiesService.pesada;
    }

    finalizarCubicaje(): void {

        this.mercaderiesService.finalitzaCubicaje(
            this.userService.getToken(),
            this.entrada.idEntrada,
            this.partida.idPartida,
            this.pesada.bultos
        ).subscribe(
            response => {
                this.resetPesada();
                this.mercaderiesService.pesadas = [];
                this.dbService.clear('pesades').subscribe((successDeleted) => {
                    global.showPreloader = false;
                });
            },
            error => {

            }
        );

    }

    finalizarPesada(): void {
        this.mercaderiesService.finalitzaPesada(
            this.userService.getToken(),
            this.entrada.idEntrada,
            this.partida.idPartida
        ).subscribe(
            response => {
                this.resetPesada();
                this.mercaderiesService.pesadas = [];
                this.dbService.clear('pesades').subscribe((successDeleted) => {
                    global.showPreloader = false;
                });
            },
            error => {

            }
        );

    }

    savePesada(): void {
        delete this.pesada.id;
        global.showPreloader = true;

        this.mercaderiesService.pesadas.push(this.pesada);
        this.dbService.clear('pesades').subscribe((successDeleted) => {
            this.dbService.bulkAdd('pesades', this.mercaderiesService.pesadas)
                .subscribe((result) => {
                    global.showPreloader = false;
                    this.toast.success('Pesada desada correctament.');
                    this.toast.info('Recorda que has de finalitzar el process per finalitzar tot el passatge');
                    this.router.navigate(['/entrada-partida', this.entrada.idEntrada, this.partida.idPartida, 'pesadas']);
                });
        });
        this.resetPesada();


    }

    changeTara(): void {
        this.pesada.tara = 0;
        this.tares.forEach((tara) => {
            this.pesada.tara += tara.pes * tara.numero;
        });
        this.pesada.pesNet = this.pesada.pesBrut - this.pesada.tara;
    }

    addPesada(): void {
        global.showPreloader = true;
        this.mercaderiesService.getInfoBascules(this.userService.getToken())
            .subscribe(
                next => {
                    global.showPreloader = false;
                    if (next.status === 'success') {

                        const BASCULA_1 = next.data.bascula_1;
                        const BASCULA_2 = next.data.bascula_2;
                        this.pesada.pesBrut = parseFloat(BASCULA_1.peso) + parseFloat(BASCULA_2.peso);
                        this.pesada.pesNet = this.pesada.pesBrut - this.pesada.tara;
                    } else {
                        alert(next.message);
                    }
                },
                error => {
                    global.showPreloader = false;
                    alert('Error al recuperar información de bascula: ' + error.message);
                }
            );
    }

    printEtiqueta(): void {
        this.mercaderiesService.printEtiquetaPesada(this.userService.getToken(), this.entrada.idEntrada, this.partida.idPartida)
            .subscribe(
                next => {
                    if (next.status === 'success') {

                    } else {
                        this.toast.error('Error al imprimir:' + next.message);
                    }
                },
                error => {
                    this.toast.error('Error al imprimir:: ' + error.message);
                }
            );
    }

    deleteBulto(index): void {

        // Seleccionamos el bulto en el indice del array
        const bulto: Bulto = this.pesada.bultos[index];

        // Añadimos los valores del bulto a la pesada
        this.pesada.numBultos -= bulto.bultos;
        this.pesada.bultosContiene -= bulto.bultosContiene;
        this.pesada.volum -= (bulto.fons * bulto.ample * bulto.alt* this.bulto.bultos);
        this.pesada.superficie -= (bulto.fons * bulto.ample *  this.bulto.bultos);
        // Eliminamos el bulto seleccionado
        this.pesada.bultos.splice(index, 1);

        // Seleccionamos el primer input
        this.el.nativeElement.querySelector('#bultosInput').select();
    }

    addBulto(): void {
        this.pesada.bultos.push(this.bulto);

        // Añadimos los valores del bulto a la pesada
        this.pesada.numBultos += this.bulto.bultos;
        this.pesada.bultosContiene += this.bulto.bultosContiene;
        this.pesada.volum += (this.bulto.fons * this.bulto.ample * this.bulto.alt * this.bulto.bultos);
        this.pesada.superficie += (this.bulto.fons * this.bulto.ample *  this.bulto.bultos);
        // Reinicimos el bulto
        this.bulto = new Bulto();

        // Hacemos focus sobre el primer input
        this.bultosEl.nativeElement.select();
    }

}
