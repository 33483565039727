import { Component, OnInit } from '@angular/core';
import { MercaderiesService } from '../../../services/mercaderies.service';
import { UserService } from '../../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { global } from '../../../services/global';

@Component({
    selector: 'app-partida-fotos',
    templateUrl: './fotos.component.html',
    styleUrls: ['./fotos.component.scss']
})
export class FotosComponent implements OnInit {

    public error: string;
    public entrada: any;
    public partida: any;
    public documents: any;
    public albara: any;
    public tares: Array<any>;
    public url: string;

    constructor(
        private mercaderiesService: MercaderiesService,
        private userService: UserService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.entrada = this.mercaderiesService.entrada;
        this.partida = this.mercaderiesService.partida;
        this.documents = this.mercaderiesService.documents;
        this.albara = this.mercaderiesService.albara;
        this.tares = this.mercaderiesService.tares;
        this.url = global.getUrl();
    }

    ngOnInit(): void {

    }

    uploadFile(files: FileList, event): void {

        global.showPreloader = true;

        this.mercaderiesService.filePartida(this.userService.getToken(), files.item(0)).subscribe(
            next => {
                event.target.type = '';
                event.target.type = 'file';
                global.showPreloader = true;
                this.mercaderiesService.getEntradaPartida(
                    this.userService.getToken(),
                    this.route.snapshot.params.entrada,
                    this.route.snapshot.params.id
                ).subscribe(
                    success => {
                        this.documents = success.documents;
                        global.showPreloader = false;
                    }, error => {
                        global.showPreloader = false;
                    }
                );
            }, error => {
                global.showPreloader = false;
            });
    }

}
