import {Component, OnInit} from '@angular/core';
import {MercaderiesService} from "../../../services/mercaderies.service";
import {UserService} from "../../../services/user.service";
import {Router} from "@angular/router";
import {global} from "../../../services/global";

@Component({
    selector: 'app-entrades',
    templateUrl: './entrades.component.html',
    styleUrls: ['./entrades.component.scss']
})
export class EntradesComponent implements OnInit {
    public entrada: string | null;
    public error: string | null;

    constructor(
        private mercaderiesService: MercaderiesService,
        private userService: UserService,
        private router: Router
    ) {
    }

    ngOnInit(): void {

    }

    change(entrada): void {
        if (entrada.length === 6) {
            global.showPreloader = true;
            this.mercaderiesService.getEntrada(this.userService.getToken(), entrada).subscribe(
                next => {
                    if (next.status === 'error') {
                        this.error = next.message;
                    } else {
                        this.mercaderiesService.entrada = next.entrada;
                        this.router.navigate(['/mercaderies/entrada', next.entrada.idEntrada]);
                    }
                    global.showPreloader = false;
                },
                error => {
                    global.showPreloader = false;
                    if(error.status === 401){
                        this.router.navigate(['/logout/1']);
                    }else{
                        this.error = error.error.message;
                    }
                }
            );
        }else{
            this.error = '';
        }

    }
}
