import {Component, NgModule, OnInit} from '@angular/core';
import {MercaderiesService} from '../../../services/mercaderies.service';
import {UserService} from '../../../services/user.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {global} from '../../../services/global';
import {Partida} from 'src/app/models/partida';
import {EntradaPartidaService} from 'src/app/services/entrada-partida.service';

import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {AppComponent} from 'src/app/app.component';

@Component({
    selector: 'app-partida-detail',
    templateUrl: './entrada-partida-detail.component.html',
    styleUrls: ['./entrada-partida-detail.component.scss']
})

export class EntradaPartidaDetailComponent implements OnInit {

    public error: string;
    public entrada: any;
    public sortida: any;
    public partida: any;
    public documents: any;
    public albara: any;
    public tares: Array<any>;
    public url: string;
    public taraSelected: string;
    public locationSelected: string;
    public zeta: number = 0;
    public partidaModel = new Partida();

    public locations: any;
    keyword = 'codi';
    keywordTara = 'nom';
    public data: any;

    constructor(
        private mercaderiesService: MercaderiesService,
        private userService: UserService,
        private router: Router,
        private route: ActivatedRoute,
        private entradaPartidaService: EntradaPartidaService
    ) {
        this.entrada = this.mercaderiesService.entrada;
        this.partida = this.mercaderiesService.partida;
        this.albara = this.mercaderiesService.albara;
        this.tares = this.mercaderiesService.tares;
        //this.locations = this.mercaderiesService.locations;
        this.url = global.getUrl();
    }

    ngOnInit(): void {
        this.mercaderiesService.getEntradaPartida(this.userService.getToken(), this.entrada.idEntrada, this.partida.idPartida).subscribe(
            next => {
                this.locations = next.locations;
                this.tares = next.tares;

                for (const taras of next.tares) {
                    if (taras.codi === this.partida.idTipusTara) {
                        this.taraSelected = taras.nom;
                    }
                }
                for (const localizacion of next.locations) {
                    if (localizacion.id === this.partida.idUbicacio) {
                        this.locationSelected = localizacion.codi;
                    }
                }
            }
        );
    }

    update() {
        this.mercaderiesService.updatePartida(this.userService.getToken(), this.partida).subscribe(
            next => {
                this.partida.ocupacioMetresCuadrats = next.partida.ocupacioMetresCuadrats;
            },
            error => {

            }
        );
    }

    selectEvent(item) {
        this.partida.idUbicacio = item.id;
        console.log(this.partida);
        this.update();
    }
    selectEventTara(item) {
        this.partida.idTipusTara = item.codi;
        console.log(this.partida);
        this.update();
    }

    onChangeSearch(val: string) {

    }

    onFocused(e) {
        // do something when input is focused
    }

    /*update() {
        this.mercaderiesService.updatePartida(this.userService.getToken(), this.partida).subscribe(
            next => {


            }
        );
    }*/

    /*saveFields() {
        this.mercaderiesService.saveNaturalesa(this.userService.getToken(), this.partida).subscribe(
            next => {

            },
            error => {

            }
        );
    }*/
}
