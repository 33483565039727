import { Component, DoCheck, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from './services/user.service';
import { global } from './services/global';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, DoCheck {
    title = 'mercaderies';

    public identity: string;
    public token: string;
    public showPreloader: boolean;

    constructor(
        private userService: UserService,
        private router: Router,
    ) {
        this.token = this.userService.getToken();
        this.identity = this.userService.getIdentity();
        this.showPreloader = global.showPreloader;
    }

    ngOnInit(): void {
        if (!this.identity) {
            this.router.navigate(['/login']);
        }
    }

    ngDoCheck(): void {
        this.showPreloader = global.showPreloader;
        this.identity = this.userService.getIdentity();
    }

}
