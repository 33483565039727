// Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SortidaPartidaRoutingModule } from './sortida-partida-routing.module';

// Components
import { MainComponent } from './components/main/main.component';
import { SortidaPartidaDetailComponent } from './components/sortida-partida-detail/sortida-partida-detail.component';
import { FotosComponent } from './components/fotos/fotos.component';
import { MesuraComponent } from './components/mesura/mesura.component';


// Services

// NgModule
@NgModule({
    declarations: [
        MainComponent,
        SortidaPartidaDetailComponent,
        FotosComponent,
        MesuraComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        SortidaPartidaRoutingModule
    ],
    exports: [
        MainComponent,
    ]
})

export class SortidaPartidaModule {
}
