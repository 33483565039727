import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Partida } from '../models/partida';
import {global} from './global';

@Injectable({
  providedIn: 'root'
})
export class EntradaPartidaService {
    public url: string;

  constructor(
      private http: HttpClient
  ) {
    this.url = global.getUrl();
  }

  //PUTS DE PALETIZAR, ESCRPRINT, ESPERILLMEDI Y ESPALETFUMIGAT


    put(token, partida: Partida) {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded').set('Authorization', token);
        return this.http.put(this.url + '/', 'json=' + JSON.stringify(partida), {headers});
    }

}
