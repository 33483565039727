<div class="row justify-content-center">
    <div class="col-12 clearfix">
        <div class="row">
            <div class="col-12 col-md-6 mt-2">
                <button [routerLink]="['/mercaderies/entrades']" class="btn btn-block btn-info">
                    Entrades
                </button>
            </div>
            <div class="col-12 col-md-6 mt-2">
                <button [routerLink]="['/mercaderies/sortides']" class="btn btn-block btn-success">
                    Sortides
                </button>
            </div>
        </div>
        <hr>
        <button [routerLink]="['/logout/1']" class="btn btn-block btn-danger mt-3">
            <i class="fa fa-sign-out"></i>
            Salir
        </button>
    </div>
</div>

