export class Partida {
    constructor(
        public idPartida: string = '',
        public idEntrada: string = '',
        public esPaletFumigat: number = 0,
        public paletizar: number = 0,
        public esPerillMedi: number = 0,
        public esCRPrint: number = 0,
        public paletizatOrigen : number = 0,
    ) {
    }
}
