<div class="row mt-3">
    <div class="col-12">
        <p>
            A continuació pots adjuntar imatges de la partida actual
        </p>
        <label for="file-partida"><b>Foto</b></label>
        <input style="display:none;" id="file-partida" #uploader type="file" name="document" (change)="uploadFile($event.target.files, $event)" class="form-control-file">
        <button class="btn btn-dark btn-block" (click)="uploader.click()">
            Pujar imatge <i class="fa fa-carrousel"></i>
        </button>
        <p class="text-danger" *ngIf="error">{{error}}</p>
    </div>

</div>


<hr>


<div class="row">
    <div class="col-6" *ngFor="let document of documents">
        <img class="img-fluid" src="{{url}}entrades/download?id={{document.id}}">
    </div>
</div>
