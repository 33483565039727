<div class="row justify-content-center">
    <div class="col-12">
        <h1>{{ page_title }}</h1>
        <p>
            Identifícate en la plataforma para comenzar!!
        </p>
        <form #loginForm="ngForm" (ngSubmit)="onSubmit(loginForm)">
            <div class="form-group">
                <label for="username">Correo electrónico</label>
                <input type="text" name="username" class="form-control" #username="ngModel" [(ngModel)]="user.username" required>

                <small *ngIf="!username.valid && username.touched" class="invalid-feedback d-block">
                    El email no es válido
                </small>
            </div>
            <div class="form-group">
                <label for="password">Contraseña</label>
                <input type="password" name="password" class="form-control" #password="ngModel" [(ngModel)]="user.password"
                       required>
                <small *ngIf="!password.valid && password.touched" class="invalid-feedback d-block">
                    La contraseña no es válida
                </small>
            </div>

            <button type="submit" value="Identificarse" class="btn btn-success btn-block" [disabled]="loginForm.invalid">
                Entrar
            </button>
        </form>
    </div>

</div>
