// Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { EntradaPartidaRoutingModule } from './entrada-partida-routing.module';

// Components
import { MainComponent } from './components/main/main.component';
import { EntradaPartidaDetailComponent } from './components/entrada-partida-detail/entrada-partida-detail.component';
import { FotosComponent } from './components/fotos/fotos.component';
import { MesuraComponent } from './components/mesura/mesura.component';
import { PesadasComponent } from './components/pesadas/pesadas.component';
import { NgxIndexedDBModule } from 'ngx-indexed-db';
import { dbConfig } from '../services/db';
import { DefectesComponent } from './components/defectes/defectes.component';


// Services

// NgModule
@NgModule({
    declarations: [
        MainComponent,
        //EntradaPartidaDetailComponent,
        FotosComponent,
        MesuraComponent,
        PesadasComponent,
        DefectesComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        EntradaPartidaRoutingModule,
        NgxIndexedDBModule.forRoot(dbConfig)
    ],
    exports: [
        MainComponent,
    ]
})

export class EntradaPartidaModule {
}
