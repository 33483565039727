<div class="container">
    <div class="row">
        <div class="col-12">
            <div class="form-check form-check-inline">
                <input class="form-check-input" id="esPaletFumigat" type="checkbox" (change)="update()" #esPaletFumigat="ngModel" name="esPaletFumigat" [(ngModel)]="partida.esPaletFumigat">
                <label class="form-check-label" for="esPaletFumigat">Fustes fumigades</label>
            </div>
        </div>

        <div class="col-12">
            <div class="form-check form-check-inline">
                <input class="form-check-input" id="paletizar" type="checkbox" (change)="update()" #paletizar="ngModel" name="paletizar" [(ngModel)]="partida.paletizar">
                <label class="form-check-label" for="paletizar">Paletizar</label>
            </div>
        </div>

        <div class="col-12">
            <div class="form-check form-check-inline">
                <input class="form-check-input" id="paletizatOrigen" type="checkbox" (change)="update()" #paletizatOrigen="ngModel" name="paletizatOrigen" [(ngModel)]="partida.paletizatOrigen">
                <label class="form-check-label" for="paletizatOrigen">Paletizat en origen</label>
            </div>
        </div>

        <div class="col-12">
            <div class="form-check form-check-inline">
                <input class="form-check-input" id="dimensionsPesEspecials" type="checkbox" (change)="update()" #dimensionsPesEspecials="ngModel" name="dimensionsPesEspecials" [(ngModel)]="partida.dimensionsPesEspecials">
                <label class="form-check-label" for="dimensionsPesEspecials">Dimensions pes especial</label>
            </div>
        </div>
        <div class="col-12">
            <div class="form-check form-check-inline">
                <input class="form-check-input" id="adr" type="checkbox" (click)="$event.preventDefault();" (change)="update()" #adr="ngModel" name="adr" [(ngModel)]="partida.adr">
                <label class="form-check-label" for="adr">ADR</label>
            </div>
        </div>
        <div class="col-12 mb-2">
            <label class="card-subtitle mb-1"><b>Nº Palets:</b></label>
            <input (change)="update()" type="number" class="form-control" placeholder="Nº Palets" aria-label="Marcas" aria-describedby="basic-addon1"
            #numeroPalets="ngModel" name="numeroPalets" [(ngModel)]="partida.numeroPalets">
        </div>

        <div class="col-12">
            <label class="card-subtitle mb-1"><b>Tipus pallet:</b></label>
            <div class="ng-autocomplete w-100">
                <ng-autocomplete
                    [data]="tares"
                    [searchKeyword]="keywordTara"
                    placeholder="{{partida.idTipusTara ? taraSelected : 'Introduce una tara'}}"
                    (selected)='selectEventTara($event)'
                    (inputChanged)='onChangeSearch($event)'
                    (inputFocused)='onFocused($event)'
                    [itemTemplate]="itemTemplateTara"
                    [notFoundTemplate]="notFoundTemplate" >
                </ng-autocomplete>

                <ng-template #itemTemplateTara let-item>
                    <a [innerHTML]="item.nom"></a>
                </ng-template>

                <ng-template #notFoundTemplate let-notFound>
                    <div [innerHTML]="notFound"></div>
                </ng-template>
            </div>
        </div>
            <div class="col-3 mt-3">
                <h6 class="card-subtitle mb-1"><b>Bultos: </b> {{partida.numeroBultos | number}}</h6>
            </div>
            <div class="col-3 mt-3">
                <h6 class="card-subtitle mb-1"><b>Pes: </b> {{partida.pes | number}}</h6>
            </div>
            <div class="col-3 mt-3">
                <h6 class="card-subtitle mb-1"><b>Volum: </b> {{partida.volum | number}}</h6>
            </div>
            <div class="col-3 mt-3">
                <h6 class="card-subtitle mb-1"><b>Ocupació m²: </b> {{partida.ocupacioMetresCuadrats | number}}</h6>
            </div>
        <div class="col-12 mt-1">
            <label class="card-subtitle mb-1"><b>Tipus embalum:</b></label>
            <input (change)="update()" type="text" class="form-control" placeholder="Tipus embalum" aria-label="Marcas" aria-describedby="basic-addon1"
            #tipusEmbalum="ngModel" name="tipusEmbalum" [(ngModel)]="partida.tipusEmbalum">
        </div>
        <div class="col-12 mt-1">
            <label class="card-subtitle mb-1"><b>Marcas:</b></label>
            <input (change)="update()" type="text" class="form-control" placeholder="Marcas" aria-label="Marcas" aria-describedby="basic-addon1"
            #marques="ngModel" name="marques" [(ngModel)]="partida.marques">
        </div>
        <div class="col-12 mt-1 mb-2">
            <label class="card-subtitle mb-1"><b>Remontabilidad en almacén:</b></label>
            <input (change)="update()" type="number" class="form-control" placeholder="Remontabilidad" aria-label="Remontabilidad" aria-describedby="basic-addon1"
            #remontabilidad="ngModel" name="remontabilidad" [(ngModel)]="partida.remontabilidad">
        </div>
        <div class="col-12  mt-3">
            <h6 class="card-subtitle mb-2"><b>Naturalesa de la mercaderia: </b> {{partida.naturalesaMercancia}}</h6>
        </div>
    </div>

    <button
        [routerLink]="['/entrada-partida', ('000000' + partida.idEntrada).slice(-6), partida.idPartida, 'fotos']"
        class="btn btn-lg btn-block btn-dark mt-2"
    >
        Imatges
    </button>
    <button
        [routerLink]="['/entrada-partida', ('000000' + partida.idEntrada).slice(-6), partida.idPartida, 'mesura']"
        class="btn btn-lg btn-block btn-dark mt-2"
    >
        Pesatge i cubicatge
    </button>
    <button
        [routerLink]="['/entrada-partida', ('000000' + partida.idEntrada).slice(-6), partida.idPartida, 'defectes']"
        class="btn btn-lg btn-block btn-dark mt-2"
    >
        Defectes

    </button>

    <div id="border" class="card w-100 mt-2">
        <div id="header" class="card-header">
            <h5 class="card-subtitle">Magatzem</h5>
        </div>
        <ul class="list-group list-group-flush">
            <!--
            <li class="list-group-item">
                <label class="card-subtitle mb-2"><b>Ocupación m2:</b></label>
                <input (change)="update()" type="number" class="form-control" placeholder="Ocupación" aria-label="Ocupacion" aria-describedby="basic-addon1"
                #ocupacioMetresCuadrats="ngModel" name="ocupacioMetresCuadrats" [(ngModel)]="partida.ocupacioMetresCuadrats"
                >
            </li>
            -->
            <li class="list-group-item">
                <label class="card-subtitle mb-2"><b>Ubicació:</b></label>
                <div class="ng-autocomplete w-100">
                    <ng-autocomplete
                      [data]="locations"
                      [searchKeyword]="keyword"
                      placeholder="{{partida.idUbicacio ? locationSelected : 'Introduce una ubicación'}}"
                      (selected)='selectEvent($event)'
                      (inputChanged)='onChangeSearch($event)'
                      (inputFocused)='onFocused($event)'
                      [itemTemplate]="itemTemplate"
                      [notFoundTemplate]="notFoundTemplate" >
                    </ng-autocomplete>

                    <ng-template #itemTemplate let-item>
                        <a [innerHTML]="item.codi"></a>
                    </ng-template>

                    <ng-template #notFoundTemplate let-notFound>
                        <div [innerHTML]="notFound"></div>
                    </ng-template>
                </div>
            </li>
        </ul>
    </div>

</div>

