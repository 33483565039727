import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { global } from "./global";
import { Pesada } from '../entrada-partida/models/pesada';
import { Bulto } from '../entrada-partida/models/bulto';
import { Partida } from '../models/partida';

@Injectable({
    providedIn: 'root'
})
export class MercaderiesService {
    public entrada: any;
    public partida: any;
    public sortida: any;
    public documents: any;
    public albara: any;
    public tares: Array<any>;
    public pesadas: Array<Pesada>;
    public pesada: Pesada;
    public locations: any;

    constructor(
        private http: HttpClient
    ) {
        this.entrada = null;
        this.partida = null;
        this.documents = null;
        this.albara = null;
        this.sortida = null;
        this.pesadas = [];
        this.locations = [];

        this.pesada = new Pesada();
    }

    getEntrada(token: string | null, entrada: string): Observable<any> {
        const params = 'entrada=' + entrada;
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Authorization', token);

        return this.http.get(global.getUrl() + 'entrades/detail?' + params, {headers: headers});
    }

    getSortida(token: string | null, sortida: string): Observable<any> {
        const params = 'sortida=' + sortida;
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Authorization', token);

        return this.http.get(global.getUrl() + 'sortides/detail?' + params, {headers: headers});
    }

    getDefectes(token: string | null, entrada: string, partida: string): Observable<any> {
        const params = 'entrada=' + entrada + '&partida=' + partida;
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Authorization', token);

        return this.http.get(global.getUrl() + 'partides/getDefectes?' + params, {headers});
    }

    updateDefectes(token: string | null, entrada: string, partida: string, defectes: any[]): Observable<any> {
        const params = 'entrada=' + entrada + '&partida=' + partida + '&defectes=' + JSON.stringify(defectes);;
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Authorization', token);

        return this.http.post(global.getUrl() + 'partides/updateDefectes',  params, {headers});
    }

    getEntradaPartida(token: string | null, entrada: string, partida: string): Observable<any> {
        const params = 'entrada=' + entrada + '&partida=' + partida;
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Authorization', token);

        return this.http.get(global.getUrl() + 'partides/detailEntrada?' + params, {headers: headers});
    }

    getSortidaPartida(token: string | null, sortida: string, partida: string): Observable<any> {
        const params = 'sortida=' + sortida + '&partida=' + partida;
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Authorization', token);

        return this.http.get(global.getUrl() + 'partides/detailSortida?' + params, {headers: headers});
    }

    fileEntrada(token, fileToUpload: File): Observable<any> {
        const endpoint = global.getUrl() + 'entrades/upload?idContingut=' + this.entrada.idEntrada;
        const formData: FormData = new FormData();
        formData.append('Documents', fileToUpload, fileToUpload.name);
        return this.http.post(endpoint, formData, {headers: {Authorization: token}});
    }

    fileSortida(token, fileToUpload: File): Observable<any> {
        const endpoint = global.getUrl() + 'sortides/upload?idContingut=' + this.sortida.idSortida;
        const formData: FormData = new FormData();
        formData.append('Documents', fileToUpload, fileToUpload.name);
        return this.http.post(endpoint, formData, {headers: {Authorization: token}});
    }

    filePartida(token, fileToUpload: File): Observable<any> {
        let endpoint = global.getUrl() + 'partides/upload?idContingut=' + this.partida.idPartida;
        let formData: FormData = new FormData();
        formData.append('Documents', fileToUpload, fileToUpload.name);
        return this.http.post(endpoint, formData, {headers: {Authorization: token}});
    }

    getInfoBascules(token: string | null): Observable<any> {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Authorization', token);

        return this.http.post(global.getUrl() + 'bascula/getInfo', null, {headers});
    }

    finalitzaPesada(token: string | null, entrada: string, partida: string): Observable<any> {
        const params = 'entrada=' + entrada + '&partida=' + partida + '&pesada=' + JSON.stringify(this.pesadas);
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Authorization', token);

        return this.http.post(global.getUrl() + 'bascula/finalitzaPesada', params, {headers});
    }

    finalitzaCubicaje(token: string | null, entrada: string, partida: string, bultos: Bulto[]): Observable<any> {
        const params = 'entrada=' + entrada + '&partida=' + partida + '&bultos=' + JSON.stringify(bultos);
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Authorization', token);

        return this.http.post(global.getUrl() + 'bascula/finalitzaCubicaje', params, {headers});
    }

    savePesada(token: string | null, pesada: Pesada, entrada: string, partida: string): Observable<any> {
        const params = 'entrada=' + entrada + '&partida=' + partida + '&pesada=' + JSON.stringify(pesada);
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Authorization', token);

        return this.http.post(global.getUrl() + 'bascula/savePesada', params, {headers});
    }

    printEtiquetaPesada(token: string | null, entrada: string, partida: string): Observable<any> {
        const params = 'entrada=' + entrada + '&partida=' + partida;
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Authorization', token);

        return this.http.post(global.getUrl() + 'partides/printEtiquetaPesada', params, {headers: headers});
    }

    updatePartida(token: string | null, partida: Partida): Observable<any> {
        const params = 'entrada=' + partida.idEntrada + '&partida=' + partida.idPartida;
        const body = 'json=' + JSON.stringify(partida);
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Authorization', token);

        return this.http.post(global.getUrl() + 'partides/updatePartida?'+ params, body, {headers: headers});
    }
}
