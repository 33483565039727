import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {User} from '../../../models/user';
import {UserService} from '../../../services/user.service';

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css'],
    providers: [UserService]
})
export class LoginComponent implements OnInit {
    public page_title: string;
    public user: User;
    public status: string;
    public token;
    public identity;

    constructor(
        private _userService: UserService,
        private _router: Router,
        private _route: ActivatedRoute
    ) {
        this.page_title = 'Identificate';
        this.user = new User(1, '', '', 'ROLE_USER', '', '', '', '', '');
    }

    ngOnInit(): void {
        // Se ejecuta siempre y cierra sesión solo cuando le llega el paramtro sure por la url
        this.logout();
    }

    onSubmit(form): void {

        //TOKEN
        this._userService.signup(this.user).subscribe(
            next => {
                if (next.status != 'error') {
                    this.status = 'success';
                    this.token = next.token;

                    // OBJETO DE USUARIO IDENTIFICADO
                    this._userService.signup(this.user, true).subscribe(
                        success => {
                            if (success.status != 'error') {
                                this.identity = success.identity;

                                // PERSISTIR DATOS USUARIO IDENTIFICADO
                                localStorage.setItem('token', this.token);
                                localStorage.setItem('identity', JSON.stringify(this.identity));
                                this._router.navigate(['/']);

                            } else {
                                this.status = 'error';
                            }

                        },
                        error => {
                            this.status = 'error';
                        }
                    );
                } else {
                    this.status = 'error';
                }

            },
            error => {
                this.status = 'error';
            }
        );
    }

    logout(): void {
        this._route.params.subscribe(params => {
            let logout = +params['sure'];

            if (logout == 1) {
                localStorage.removeItem('identity');
                localStorage.removeItem('token');

                this.identity = null;
                this.token = null;

                // Redirección a inicio
                this._router.navigate(['/login']);

            }
        });
    }

}
