import { Component, DoCheck, OnInit } from '@angular/core';
import { MercaderiesService } from '../../../services/mercaderies.service';
import { UserService } from '../../../services/user.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { global } from '../../../services/global';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Pesada } from '../../models/pesada';

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

    public status: string;
    public entrada: any;
    public partida: any;
    public documents: any;
    public albara: any;
    public location: any;
    public tares: Array<any>;
    public url: string;

    constructor(
        private mercaderiesService: MercaderiesService,
        private userService: UserService,
        private router: Router,
        private route: ActivatedRoute,
        private dbService: NgxIndexedDBService
    ) {
        this.entrada = this.mercaderiesService.entrada;
        this.partida = this.mercaderiesService.partida;
        this.url = global.getUrl();

        this.partida.esPaletFumigat = parseInt(this.partida.esPaletFumigat, 10);
        this.partida.paletizar = parseInt(this.partida.paletizar, 10);
        this.partida.esPerillMedi = parseInt(this.partida.esPerillMedi, 10);
        this.partida.esCRPrint = parseInt(this.partida.esCRPrint, 10);
        this.partida.paletizatOrigen = parseInt(this.partida.paletizatOrigen, 10);

    }

    ngOnInit(): void {
        this.route.firstChild.params.subscribe((params: Params) => {
            this.reloadData(params);
        });
    }

    reloadData(params: Params): void {
        if (
            !this.partida
            || !this.albara
            || params.entrada !== this.mercaderiesService.entrada.idEntrada
            || params.id !== this.mercaderiesService.partida.idPartida
            || !this.documents
            || !this.tares
        ) {
            global.showPreloader = true;
            this.mercaderiesService.getEntradaPartida(
                this.userService.getToken(),
                params.entrada,
                params.id
            ).subscribe(
                next => {
                    if (next.status === 'error') {
                        this.router.navigate(['/mercaderies/entrada/:id', params.entrada]);
                    } else {
                        this.entrada = next.entrada;
                        this.partida = next.partida;

                        // Actualizamos el servicio
                        this.mercaderiesService.entrada = next.entrada;
                        this.mercaderiesService.partida = next.partida;
                        this.mercaderiesService.documents = next.documents;
                        this.mercaderiesService.albara = next.albara;
                        this.mercaderiesService.tares = next.tares;
                        this.mercaderiesService.tares.forEach((tara) => {
                            tara.pes = parseFloat(tara.pes);
                            tara.numero = 0;
                        });

                        this.dbService.getAll('pesades')
                            .subscribe(
                                (pesades: Array<Pesada>) => {
                                    this.mercaderiesService.pesadas  = pesades;
                                });
                    }
                    global.showPreloader = false;
                },
                error => {
                    this.router.navigate(['/mercaderies/entrada/:id', params.entrada]);
                    global.showPreloader = false;
                }
            );
        }
    }
}
