import { Component, OnInit } from '@angular/core';
import { MercaderiesService } from '../../../services/mercaderies.service';
import { UserService } from '../../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-defectes',
    templateUrl: './defectes.component.html',
    styleUrls: ['./defectes.component.scss']
})
export class DefectesComponent implements OnInit {

    public defectes: any[] = [];
    public error: string;
    public entrada: any;
    public partida: any;

    constructor(
        private mercaderiesService: MercaderiesService,
        private userService: UserService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.entrada = this.mercaderiesService.entrada;
        this.partida = this.mercaderiesService.partida;
    }

    ngOnInit(): void {
        this.loadDefectes();
    }

    loadDefectes(): void {
        this.mercaderiesService.getDefectes(this.userService.getToken(), this.entrada.idEntrada, this.partida.idPartida).subscribe(
            next => {
                this.defectes = next.defectes;
            },
            error => {
                this.error = 'Error loading defects';
            }
        );
    }

    changed(): void {
        this.mercaderiesService.updateDefectes(this.userService.getToken(), this.entrada.idEntrada, this.partida.idPartida, this.defectes).subscribe(
            next => {
                // Reload the defects to refresh the view
                this.loadDefectes();
            },
            error => {
                this.error = 'Error updating defects';
            }
        );
    }


}
