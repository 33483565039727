<h1 class="mt-5" *ngIf="sortida" [routerLink]="['/mercaderies/sortida', ('000000' + sortida.idSortida).slice(-6)]">Sortida: {{('000000' + sortida.idSortida).slice(-6)}}</h1>

<div class="row mt-3">
    <div class="col-12 align-self-center">
        <p>
            Si vols accedir a alguna partida d'aquesta sortida introdueixa a continuació
        </p>
        <div class="alert alert-danger" *ngIf="error">{{error}}</div>
        <div class="form-group">
            <label for="partida-input"><b>Partida</b></label>
            <input id="partida-input" (ngModelChange)="partidaChange($event)" [(ngModel)]="partida" placeholder="000000" type="text" name="partida" class="form-control">
            <p class="text-danger" *ngIf="error">{{error}}</p>
        </div>
    </div>
</div>



<div class="row mt-5">
    <div class="col-12">
        <p>
            A continuació pots adjuntar imatges de la sortida actual
        </p>
        <label for="file-sortida"><b>Foto</b></label>
        <input style="display:none;" id="file-sortida" #uploader type="file" name="document" (change)="uploadFile($event.target.files, $event)" class="form-control-file">
        <button class="btn btn-dark btn-block" (click)="uploader.click()">
            Pujar imatge <i class="fa fa-carrousel"></i>
        </button>
        <p class="text-danger" *ngIf="error">{{error}}</p>
    </div>

</div>


<hr>

<div class="row mb-3">
    <div class="col-6 mt-4" *ngFor="let document of documents">
        <img class="img-fluid" src="{{url}}sortides/download?id={{document.id}}">
    </div>
</div>

