import { Bulto } from './bulto';

export class Pesada {
    constructor(
        public id: number = null,
        public pesNet: number = 0,
        public tara: number = 0,
        public pesBrut: number = 0,
        public bultos: Array<Bulto> = [],
        public numBultos: number = 0,
        public bultosContiene: number = 0,
        public ample: number = 0,
        public alt: number = 0,
        public fons: number = 0,
        public volum: number = 0,
        public entrada: string = '',
        public partida: string = '',
        public pesada: string = '',
        public bascula: number = null

    ) {

    }
}
