<div class="container">
    <div class="row">
        <div class="col-12 col-md-12">
            <div class="row">
                <div class="totals col-12 col-md-12">
                    <button id="finalizar-pesaje" (click)="finalizarPesada()" type="button" class="btn btn-success btn-block">
                       FINALIZAR PROCESO <i class="fa fa-check"></i>
                    </button>
                    <hr>

                    <button (click)="savePesada()" type="button" class="btn btn-primary btn-block">
                        GUARDAR PESADA <i class="fa fa-save"></i>
                    </button>
                    <button (click)="resetPesada()" type="button" class="btn btn-warning btn-block">
                        LIMPIAR PESADA ACTUAL <i class="fa fa-recycle"></i>
                    </button>
                </div>
            </div>
            <div class="totals row mt-3">

                <div class="col-6">
                    <b>PB:</b> {{pesada.pesBrut}}kg
                </div>

                <div class="col-6">
                    <b>PN:</b> {{pesada.pesNet}}kg
                </div>
                <div class="col-6">
                    <b>Bultos:</b>  {{pesada.numBultos}}
                </div>
                <div class="col-6">
                    <b>Bul. Cont:</b>{{pesada.bultosContiene}}
                </div>
                <div class="col-6">
                    <b>TARA:</b> {{pesada.tara}}
                </div>
                <div class="col-6">
                    <b>Vol. (m³)</b> {{pesada.volum}}
                </div>
                <div class="col-6">
                    <b>Bult. Alb.</b> {{albara.sumaBultos}}
                </div>
                <div class="col-6">
                    <b>Vol. Alb. (m³)</b> {{albara.sumaVolum}}
                </div>
            </div>
            <div class="row mt-3 mb-3">
                <div class="refresh-totals col-12 text-right">
                    <i title="PESAR" (click)="addPesada()" class="fa fa-balance-scale fa-2x"></i>
                    <i title="Ver lista de pesadas"  [routerLink]="['/entrada-partida', ('000000' + partida.idEntrada).slice(-6), partida.idPartida, 'pesadas']" class="fa fa-list fa-2x"></i>
                    <i title="Imprimir etiqueta de pesaje" (click)="printEtiqueta()"
                       class="fa fa-print fa-2x"></i>
                </div>
            </div>

        </div>
    </div>
</div>
<div id="accordion">
    <div class="card">
        <div class="card-header" id="headingCubicar">
            <h5 class="mb-0">
                <button class="btn btn-link collapsed" [attr.data-toggle]="'collapse'"
                        [attr.data-target]="'#collapseCubicar'" [attr.aria-expanded]="'false'"
                        [attr.aria-controls]="'collapseCubicar'">
                    Cubicar
                </button>
                <button class="btn btn-primary pull-right" (click)="finalizarCubicaje()">
                    Finalizar proceso (SOLO CUBICAR)
                </button>
            </h5>
        </div>
        <div id="collapseCubicar" class="collapse" [attr.aria-labelledby]="'headingCubicar'"
             [attr.data-parent]="'#accordion'">
            <div class="card-body">
                <div class="col-xs-12">
                    <form #bultosForm="ngForm" (ngSubmit)="addBulto()">
                        <table class="table-bultos text-center">
                            <thead>
                            <tr>
                                <th>Nº Bultos</th>
                                <th>Bultos Cont.</th>
                                <th>Llarg(m)</th>
                                <th>Ample(m)</th>
                                <th>Alt(m)</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let itemBulto of pesada.bultos; let i = index">
                                <td class="t-b">
                                    {{itemBulto.bultos}}
                                </td>
                                <td class="t-bc">
                                    {{itemBulto.bultosContiene}}
                                </td>
                                <td class="t-am">
                                    {{itemBulto.fons}}
                                </td>
                                <td class="t-f">
                                    {{itemBulto.ample}}
                                </td>
                                <td class="t-a">
                                    {{itemBulto.alt}}
                                </td>
                                <td>
                                    <i (click)="deleteBulto(i)" class="fa fa-trash-o"></i>
                                </td>
                            </tr>
                            <tr>
                                <td class="t-b">
                                    <input name="bultos" #bultos="ngModel"
                                           #bultosEl
                                           [(ngModel)]="bulto.bultos"
                                           min="0" type="number" step="0"
                                           class="text-center  form-control"
                                    >
                                </td>
                                <td class="t-bc">
                                    <input  name="bultosContiene"

                                           #bultosContiene="ngModel"
                                           [(ngModel)]="bulto.bultosContiene" min="0" type="number" step="0" value="0"
                                           class="text-center  form-control"
                                    >
                                </td>
                                <td class="t-f">
                                    <input name="fons" #fons="ngModel"
                                           [(ngModel)]="bulto.fons"
                                           min="0" type="number"
                                           step="1" value="0.00"
                                           class="text-center  form-control"
                                    >
                                </td>
                                <td class="t-am">
                                    <input name="ample" #ample="ngModel"
                                           [(ngModel)]="bulto.ample"
                                           id="c-am" min="0"
                                           type="number" step="1" value="0.00"
                                           class="text-center  form-control"
                                    >
                                </td>
                                <td class="t-a">
                                    <input name="alt" #alt="ngModel"
                                           [(ngModel)]="bulto.alt" min="0"
                                           type="number"
                                           step="1" value="0.00"
                                           class="text-center  form-control"
                                    >
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td colspan="6">
                                    <input type="submit" class="btn btn-success btn-lg btn-block mt-2" value="Añadir">
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="card">
        <div class="card-header" id="headingPesar">
            <h5 class="mb-0">
                <button class="btn btn-link collapsed" [attr.data-toggle]="'collapse'"
                        [attr.data-target]="'#collapsePesar'" [attr.aria-expanded]="'false'"
                        [attr.aria-controls]="'collapsePesar'">
                    Tara
                </button>
            </h5>
        </div>
        <div id="collapsePesar" class="collapse" [attr.aria-labelledby]="'headingPesar'"
             [attr.data-parent]="'#accordion'">
            <div class="card-body">
                <div class="col-xs-12">
                    <fieldset id="bascula" class="bascula col-xs-12">
                        <legend>BASCULA 1 (160x160)</legend>
                        <div class="treeview">
                            <div class="last">
                                <h4>Tarar</h4>
                                <div class="lista-palets">
                                    <div *ngFor="let tara of tares" class="row tara">
                                        <div class="col-3">
                                            <input (change)="changeTara()" type="number" min="0" class="form-control"
                                                   name="{{ tara.codi }}"
                                                   value="{{tara.numero}}" [(ngModel)]="tara.numero">
                                        </div>
                                        <div class="col-9 tara-label">
                                            <label>
                                                <b>{{tara.nom}} (<span
                                                class="tara-total-suma">{{tara.pes}}</span>kg)</b>
                                            </label>
                                        </div>


                                    </div>

                                    <div class="row">

                                        <div class="col-4 tara-label">
                                            <label>
                                                <b>Tara total:</b>
                                            </label>
                                        </div>
                                        <div class="col-8">
                                            <input type="number" min="0" class="form-control"
                                                   name="taraTotal"
                                                   value="{{pesada.tara}}" [(ngModel)]="pesada.tara">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
            </div>
        </div>
    </div>
</div>
