import { Component, OnInit } from '@angular/core';
import { MercaderiesService } from '../../../services/mercaderies.service';
import { UserService } from '../../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { global } from '../../../services/global';

@Component({
    selector: 'app-partida-detail',
    templateUrl: './sortida-partida-detail.component.html',
    styleUrls: ['./sortida-partida-detail.component.scss']
})
export class SortidaPartidaDetailComponent implements OnInit {

    public error: string;
    public sortida: any;
    public partida: any;
    public documents: any;
    public albara: any;
    public tares: Array<any>;
    public url: string;

    constructor(
        private mercaderiesService: MercaderiesService,
        private userService: UserService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.partida = this.mercaderiesService.partida;
        this.albara = this.mercaderiesService.albara;
        this.tares = this.mercaderiesService.tares;
        this.url = global.getUrl();
    }

    ngOnInit(): void {

    }

}
