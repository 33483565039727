<div class="container">
    <nav class="nav navbar-dark bg-dark mb-3">
        <a class="nav-link text-white" *ngIf="entrada" [routerLink]="['/mercaderies/entrada', ('000000' + entrada.idEntrada).slice(-6)]">Entrada: {{('000000' + entrada.idEntrada).slice(-6)}}</a>
        <a class="nav-link text-white" *ngIf="partida" [routerLink]="['/entrada-partida', ('000000' + partida.idEntrada).slice(-6), partida.idPartida]">Partida: {{('000000' + partida.idPartida).slice(-6)}}</a>
    </nav>
</div>



<router-outlet *ngIf="entrada && partida"></router-outlet>
