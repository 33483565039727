import { Component, OnInit } from '@angular/core';
import { MercaderiesService } from "../../../services/mercaderies.service";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { UserService } from "../../../services/user.service";
import { global } from "../../../services/global";


@Component({
    selector: 'app-entrada-detail',
    templateUrl: './entrada-detail.component.html',
    styleUrls: ['./entrada-detail.component.scss']
})

export class EntradaDetailComponent implements OnInit {

    public error: string;
    public entrada: any;
    public pma: boolean = false;
    public pmamax: any;
    public test: any;
    public partida: any;
    public documents: any;
    public url: string;

    constructor(
        private mercaderiesService: MercaderiesService,
        private userService: UserService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.entrada = this.mercaderiesService.entrada;
        this.url = global.getUrl();
    }

    ngOnInit(): void {
        this.route.params.subscribe((params: Params) => {
            global.showPreloader = true;
            this.mercaderiesService.getEntrada(this.userService.getToken(), params.id).subscribe(
                next => {
                    if (next.status === 'error') {
                        this.router.navigate(['/mercaderies/entrades']);
                    } else {
                        this.entrada = next.entrada;
                        this.documents = next.documents;
                        this.mercaderiesService.entrada = next.entrada;
                        this.pma = next.pma;
                    }
                    global.showPreloader = false;
                },
                error => {
                    global.showPreloader = false;
                    this.router.navigate(['/mercaderies/entrades']);
                }
            );
        });
    }

    uploadFile(files: FileList, event): void {
        global.showPreloader = true;
        this.mercaderiesService.fileEntrada(this.userService.getToken(), files.item(0)).subscribe(
            next => {
                event.target.type = '';
                event.target.type = 'file';
                this.mercaderiesService.getEntrada(
                    this.userService.getToken(),
                    this.route.snapshot.params.id
                ).subscribe(
                    success => {
                        this.documents = success.documents;
                        global.showPreloader = false;
                    }, error => {
                        global.showPreloader = false;
                    }
                );
            }, error => {
                global.showPreloader = false;
                if (error.status === 401) {
                    this.router.navigate(['/logout/1']);
                } else {
                    this.error = error.error.message;
                }
            });
    }

    partidaChange(partida): void {
        if (partida.length === 6) {
            global.showPreloader = true;
            this.mercaderiesService.getEntradaPartida(this.userService.getToken(), this.entrada.idEntrada, partida).subscribe(
                next => {
                    if (next.status === 'error') {
                        this.error = next.message;
                    } else {
                        this.mercaderiesService.partida = next.partida;
                        this.router.navigate(['/entrada-partida', next.entrada.idEntrada, next.partida.idPartida]);
                    }
                    global.showPreloader = false;
                },
                error => {
                    global.showPreloader = false;
                    if (error.status === 401) {
                        this.router.navigate(['/logout/1']);
                    } else {
                        this.error = error.error.message;
                    }
                }
            );
        } else {
            this.error = '';
        }
    }

}
