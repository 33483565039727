export class Bulto {

    constructor(
        public id: number = null,
        public bultos: number = 0,
        public bultosContiene: number = 0,
        public alt: number = 0,
        public fons: number = 0,
        public ample: number = 0
    ) {
    }
}
