<nav id="topbar" class="navbar navbar-expand-lg navbar-light">
    <div class="container">
        <a class="navbar-brand" href="#">TRM</a>
    </div>
</nav>
<div class="container mt-3">
    <router-outlet></router-outlet>
</div>
<div class="preloader" *ngIf="showPreloader">
    <i class="fa fa-refresh fa-spin"></i>
</div>
