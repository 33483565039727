import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/user/login/login.component';
import { NotFoundComponent } from './components/error/not-found/not-found.component';
import { IdentityGuard } from './services/identity.guard';
import { HomeComponent } from './components/home/home.component';
import { EntradesComponent } from './components/mercaderies/entrades/entrades.component';
import { EntradaDetailComponent } from './components/mercaderies/entrada-detail/entrada-detail.component';
import { SortidaDetailComponent } from './components/mercaderies/sortides-detail/sortida-detail.component';
import { SortidesComponent } from './components/mercaderies/sortides/sortides.component';

const appRoutes: Routes = [
    {path: '', component: HomeComponent, canActivate: [IdentityGuard]},
    {path: 'mercaderies/entrades', component: EntradesComponent, canActivate: [IdentityGuard]},
    {path: 'mercaderies/entrada/:id', component: EntradaDetailComponent, canActivate: [IdentityGuard]},
    {path: 'mercaderies/sortides', component: SortidesComponent, canActivate: [IdentityGuard]},
    {path: 'mercaderies/sortida/:id', component: SortidaDetailComponent, canActivate: [IdentityGuard]},
    {path: 'login', component: LoginComponent},
    {path: 'logout/:sure', component: LoginComponent},
    {path: '**', component: NotFoundComponent}
];


// Exportar configuracion
export const appRoutingProviders: any[] = [];
export const routing: ModuleWithProviders<any> = RouterModule.forRoot(appRoutes);
