<div class="container">
    <div class="row">
        <div class="col-xs-12">
            <div id="alerta-cubicada" class="alert alert-warning">
                <p>Cuidado: Esta partida ya está cubicada</p>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-6">
            <div class="row">
                <div class="totals col-12 col-md-6">
                    <button id="finalizar-pesaje" type="button" class="btn btn-primary">
                        <i class="fa fa-check"></i>
                        <span>FINALIZAR PROCESO</span>
                    </button>
                    <i title="Nuevo Pesaje" class="fa fa-plus"></i>
                    <i id="nuevo-pesaje" title="Nuevo Pesaje" (click)="savePesada()" class="fa fa-balance-scale fa-3x"></i>
                </div>
            </div>
            <div class="totals row mt-3">

                <div class="col-6">
                    <b>Peso Bruto:</b> {{pesada.pesBrut}}kg
                </div>

                <div class="col-6">
                    <b>Peso neto:</b> {{pesada.pesNet}}kg
                </div>
                <div class="col-6">
                    <b>Bultos:</b>  {{pesada.numBultos}}
                </div>
                <div class="col-6">
                    <b>Bultos Contiene:</b>{{pesada.bultosContiene}}
                </div>
                <div class="col-6">
                    <b>TARA:</b> {{pesada.tara}}
                </div>
                <div class="col-6">
                    <b>Volumen (m³)</b> {{pesada.volum}}
                </div>
                <div class="col-6">
                    <b>Bultos Albaran</b> {{albara.sumaBultos}}
                </div>
                <div class="col-6">
                    <b>Vol. Albaran (m³)</b> {{albara.sumaVolum}}
                </div>
            </div>
            <div class="refresh-totals col-12 text-right">
                <i id="pesar" title="PESAR" class="fa fa-balance-scale fa-2x"></i>
                <i id="pesadas" title="Ver lista de pesadas" class="fa fa-list fa-2x"></i>
                <i id="print-etiqueta" title="Imprimir etiqueta de pesaje" (click)="printEtiqueta()" class="fa fa-print fa-2x"></i>
            </div>
        </div>
    </div>
</div>
<div id="accordion">
    <div class="card">
        <div class="card-header" id="headingCubicar">
            <h5 class="mb-0">
                <button class="btn btn-link collapsed" [attr.data-toggle]="'collapse'"
                        [attr.data-target]="'#collapseCubicar'" [attr.aria-expanded]="'false'"
                        [attr.aria-controls]="'collapseCubicar'">
                    Cubicar
                </button>
            </h5>
        </div>
        <div id="collapseCubicar" class="collapse" [attr.aria-labelledby]="'headingCubicar'"
             [attr.data-parent]="'#accordion'">
            <div class="card-body">
                <div class="col-xs-12">
                    <form #bultosForm="ngForm" (ngSubmit)="addBulto(bultosForm)">
                        <table class="table-bultos text-center">
                            <thead>
                            <tr>
                                <th>Nº Bultos</th>
                                <th>Bultos Cont.</th>
                                <th>Largo</th>
                                <th>Ancho</th>
                                <th>Alto</th>
                                <th>Acciones</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let itemBulto of pesada.bultos; let i = index">
                                <td class="t-b">
                                    {{itemBulto.bultos}}
                                </td>
                                <td class="t-bc">
                                    {{itemBulto.bultosContiene}}
                                </td>
                                <td class="t-am">
                                    {{itemBulto.ample}}
                                </td>
                                <td class="t-f">
                                    {{itemBulto.fons}}
                                </td>
                                <td class="t-a">
                                    {{itemBulto.alt}}
                                </td>
                                <td>
                                    <i (click)="deleteBulto(i)" class="fa fa-trash-o"></i>
                                </td>
                            </tr>
                            <tr>
                                <td class="t-b">
                                    <input id="bultosInput" name="bultos" #bultos="ngModel" [(ngModel)]="bulto.bultos"
                                           min="0" type="number" step="0">
                                </td>
                                <td class="t-bc">
                                    <input name="bultosContiene" #bultosContiene="ngModel"
                                           [(ngModel)]="bulto.bultosContiene" min="0" type="number" step="0" value="0">
                                </td>
                                <td class="t-am">
                                    <input name="ample" #ample="ngModel" [(ngModel)]="bulto.ample" id="c-am" min="0"
                                           type="number" step="2" value="0.00">
                                </td>
                                <td class="t-f">
                                    <input name="fons" #fons="ngModel" [(ngModel)]="bulto.fons" min="0" type="number"
                                           step="2" value="0.00">
                                </td>
                                <td class="t-a">
                                    <input name="alt" #alt="ngModel" [(ngModel)]="bulto.alt" min="0" type="number"
                                           step="2" value="0.00">
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td colspan="6">
                                    <input type="submit" class="btn btn-success btn-lg btn-block" value="Añadir">
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="card">
        <div class="card-header" id="headingPesar">
            <h5 class="mb-0">
                <button class="btn btn-link collapsed" [attr.data-toggle]="'collapse'"
                        [attr.data-target]="'#collapsePesar'" [attr.aria-expanded]="'false'"
                        [attr.aria-controls]="'collapsePesar'">
                    Pesar
                </button>
            </h5>
        </div>
        <div id="collapsePesar" class="collapse" [attr.aria-labelledby]="'headingPesar'"
             [attr.data-parent]="'#accordion'">
            <div class="card-body">
                <div class="col-xs-12">
                    <fieldset id="bascula" class="bascula col-xs-12">
                        <legend>BASCULA 1 (160x160)</legend>
                        <ul class="treeview">
                            <li class="last">
                                <label class="custom-unchecked">Activa</label>

                                <ul>
                                    <li>
                                        <label class="custom-unchecked">Tarar</label>
                                        <ul class="lista-palets">

                                            <li *ngFor="let tara of tares">
                                                <input type="number" min="0" class="tara"
                                                       name="{{ tara.codi }}"
                                                       value="{{tara.pes}}">
                                                <label class="custom-unchecked">Tara nom</label>
                                                <b>{{tara.nom}} (<span
                                                    class="tara-total-suma">{{tara.pes}}</span>kg)</b>
                                            </li>

                                            <li class="total-tara-container">
                                                <label class="custom-unchecked">TARA TOTAL</label>
                                                <input type="number" min="0" class="tara tara-total" value="0.00">
                                                <b>Kg</b>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <input type="checkbox" name="bascula-cubicar" class="bascula-cubicar">
                                        <label class="custom-unchecked">Cubicar</label>

                                        <span class="text-danger">Esta partida ya está cubicada <a
                                            class="btn btn-primary"
                                            target="_blank">VER</a></span>


                                    </li>
                                    <li>
                                        <input type="checkbox" name="bascula-recuento" class="bascula-recuento">
                                        <label class="custom-unchecked">Recuento</label>
                                        <div class="col-xs-12">
                                            <input type="number" class="recuento" name="recuento" value="0">
                                            <label class="custom-unchecked">Bultos</label>
                                        </div>
                                    </li>

                                </ul>
                            </li>

                        </ul>
                    </fieldset>
                </div>
            </div>
        </div>
    </div>
</div>
