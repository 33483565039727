import { Component, DoCheck, OnInit } from '@angular/core';
import { Pesada } from '../../models/pesada';
import { MercaderiesService } from '../../../services/mercaderies.service';
import { Bulto } from '../../models/bulto';
import { NgxIndexedDBService } from 'ngx-indexed-db';

@Component({
    selector: 'app-pesadas',
    templateUrl: './pesadas.component.html',
    styleUrls: ['./pesadas.component.scss']
})
export class PesadasComponent implements OnInit, DoCheck {

    public entrada: any;
    public partida: any;
    public pesades: Array<Pesada>;

    constructor(
        private mercaderiesService: MercaderiesService,
        private dbService: NgxIndexedDBService
    ) {
        this.pesades = this.mercaderiesService.pesadas;
        this.entrada = this.mercaderiesService.entrada;
        this.partida = this.mercaderiesService.partida;
    }

    ngOnInit(): void {
    }

    ngDoCheck(): void {
        this.pesades = this.mercaderiesService.pesadas;
    }

    deletePesada(pesada: Pesada): void {
        this.mercaderiesService.pesadas = this.mercaderiesService.pesadas.filter(item => item !== pesada);
        this.dbService.clear('pesades')
            .subscribe(next => console.log('All cleared'));
        this.dbService.bulkAdd('pesades', this.mercaderiesService.pesadas);
    }

    editarPesada(pesada: Pesada): void {
        this.mercaderiesService.pesada = pesada;
    }

    deleteBulto(pesada: Pesada, bulto: Bulto): void {
        const pesadaIndex = this.mercaderiesService.pesadas.indexOf(pesada);
        if (pesadaIndex !== -1) {
            this.mercaderiesService.pesadas[pesadaIndex].bultos = this.mercaderiesService
                .pesadas[pesadaIndex]
                .bultos
                .filter(item => item !== bulto);
            this.dbService.clear('pesades')
                .subscribe(next => console.log('All cleared'));
            this.dbService.bulkAdd('pesades', this.mercaderiesService.pesadas);
        } else {

        }
    }
}
