import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
    private toast: ToastrService;

    constructor(
        private inj: Injector,
    ) {
        this.toast = this.inj.get(ToastrService);
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request)
            .pipe(
                map((event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) {
                       if(event.body.message) {
                           this.toast.info(event.body.message);
                       }
                    }
                    return event;
                }),
                catchError( error => {
                    if (error.error.message) {
                        this.toast.error(error.error.message);
                    }
                    return throwError(error);
                })
            );
    }
}
