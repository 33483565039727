import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { appRoutingProviders, routing } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/user/login/login.component';
import { NotFoundComponent } from './components/error/not-found/not-found.component';
import { IdentityGuard } from './services/identity.guard';
import { UserService } from './services/user.service';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HomeComponent } from './components/home/home.component';
import { EntradesComponent } from './components/mercaderies/entrades/entrades.component';
import { EntradaDetailComponent } from './components/mercaderies/entrada-detail/entrada-detail.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EntradaPartidaModule } from './entrada-partida/entrada-partida.module';
import { SortidesComponent } from './components/mercaderies/sortides/sortides.component';
import { SortidaDetailComponent } from './components/mercaderies/sortides-detail/sortida-detail.component';
import { SortidaPartidaModule } from './sortida-partida/sortida-partida.module';
import { ResponseInterceptor } from './interceptors/response.interceptor';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { EntradaPartidaDetailComponent } from './entrada-partida/components/entrada-partida-detail/entrada-partida-detail.component';


@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        NotFoundComponent,
        HomeComponent,
        EntradesComponent,
        EntradaDetailComponent,
        SortidesComponent,
        SortidaDetailComponent,
        EntradaPartidaDetailComponent
    ],
    imports: [
        BrowserModule.withServerTransition({appId: 'serverApp'}),
        routing,
        FormsModule,
        HttpClientModule,
        ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
        BrowserAnimationsModule,
        EntradaPartidaModule,
        SortidaPartidaModule,
        ToastrModule.forRoot(),
        AutocompleteLibModule
    ],
    providers: [
        IdentityGuard,
        UserService,
        appRoutingProviders,
        {provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
