<div class="container">
    <div class="row">
        <div class="col-12">
            <i class="fa fa-arrow-left fa-3x"  [routerLink]="['/entrada-partida', ('000000' + partida.idEntrada).slice(-6), partida.idPartida, 'mesura']"></i>
        </div>
    </div>
    <div class="row mt-3">
        <table id="table-pesades" class="table table-striped table-hover">
            <thead>
            <tr>
                <th>ID</th>
                <th>PN</th>
                <th>TARA</th>
                <th>PB</th>
                <th>Bultos</th>
                <th>Bulto Contiene</th>
                <th>Ample</th>
                <th>Fons</th>
                <th>Alt</th>
                <th>Volum Total</th>
                <th>Superficie Total</th>
                <th>Eliminar</th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let pesada of pesades; let id = index">
                <tr>
                    <td><b>{{id+1}}</b></td>
                    <td>{{pesada.pesNet}}</td>
                    <td>{{pesada.tara}}</td>
                    <td>{{pesada.pesBrut}}</td>
                    <td>{{pesada.numBultos}}</td>
                    <td>{{pesada.bultosContiene}}</td>
                    <td>{{!pesada.bultos.length ? (pesada.ample | number) : ''}}</td>
                    <td>{{!pesada.bultos.length ? (pesada.fons |number ): ''}}</td>
                    <td>{{!pesada.bultos.length ? (pesada.alt | number) : ''}}</td>
                    <td>{{pesada.bultos.length ? (pesada.volum | number): ''}}</td>
                    <td>{{pesada.bultos.length ? (pesada.superficie | number): ''}}</td>
                    <td>
                        <i class="fa fa-trash text-danger fa-2x" (click)="deletePesada(pesada)"></i>
                        <i class="fa fa-trash text-danger fa-2x d-none" (click)="editarPesada(pesada)"></i>
                    </td>
                </tr>

                <tr *ngIf="pesada.bultos.length">
                    <th colspan="3"></th>
                    <th>LINEA</th>
                    <th>Bultos</th>
                    <th>Bultos contiene</th>
                    <th>Ample</th>
                    <th>Fons</th>
                    <th>Alt</th>
                    <th>Volum</th>
                    <th>Superficie</th>
                    <th></th>
                </tr>
                <tr *ngFor="let bulto of pesada.bultos; let i = index">
                    <td colspan="3"></td>
                    <td><b>{{i+1}}</b></td>
                    <td>{{bulto.bultos}}</td>
                    <td>{{bulto.bultosContiene}}</td>
                    <td>{{bulto.ample | number}}</td>
                    <td>{{bulto.fons | number}}</td>
                    <td>{{bulto.alt | number}}</td>
                    <td>{{(bulto.alt * bulto.fons * bulto.ample * bulto.bultos) | number }}</td>
                    <td>{{(bulto.fons * bulto.bultos * bulto.ample) | number}}</td>
                    <td>
                        <i class="fa fa-trash text-danger fa-2x" (click)="deleteBulto(pesada, bulto)"></i>
                    </td>
                </tr>
            </ng-container>

            </tbody>
        </table>
    </div>
</div>
