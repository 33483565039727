import { Component, DoCheck, OnInit } from '@angular/core';
import { MercaderiesService } from '../../../services/mercaderies.service';
import { UserService } from '../../../services/user.service';
import { ActivatedRoute, Params, Router, RoutesRecognized } from '@angular/router';
import { global } from '../../../services/global';

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, DoCheck {

    public status: string;
    public sortida: any;
    public partida: any;
    public documents: any;
    public albara: any;
    public tares: Array<any>;
    public url: string;

    constructor(
        private mercaderiesService: MercaderiesService,
        private userService: UserService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.sortida = this.mercaderiesService.sortida;
        this.partida = this.mercaderiesService.partida;
        this.url = global.getUrl();


    }

    ngOnInit(): void {
        this.route.firstChild.params.subscribe((params: Params) => {
            this.reloadData(params);
        });
    }

    ngDoCheck(): void {
    }

    reloadData(params: Params): void {
        if (
            !this.partida
            || !this.albara
            || params.sortida !== this.mercaderiesService.sortida.idSortida
            || params.id !== this.mercaderiesService.partida.idPartida
            || !this.documents
            || !this.tares
        ) {
            global.showPreloader = true;
            this.mercaderiesService.getSortidaPartida(
                this.userService.getToken(),
                params.sortida,
                params.id
            ).subscribe(
                next => {
                    if (next.status === 'error') {
                        this.router.navigate(['/mercaderies/sortida/:id', params.sortida]);
                    } else {
                        this.sortida = next.sortida;
                        this.partida = next.partida;

                        // Actualizamos el servicio
                        this.mercaderiesService.sortida = next.sortida;
                        this.mercaderiesService.partida = next.partida;
                        this.mercaderiesService.documents = next.documents;
                        this.mercaderiesService.albara = next.albara;
                        this.mercaderiesService.tares = next.tares;
                    }
                    global.showPreloader = false;
                },
                error => {
                    this.router.navigate(['/mercaderies/sortida/:id', params.sortida]);
                    global.showPreloader = false;
                }
            );
        }
    }
}
