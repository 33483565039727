import { Component, OnInit } from '@angular/core';
import { MercaderiesService } from '../../../services/mercaderies.service';
import { UserService } from '../../../services/user.service';
import { Router } from '@angular/router';
import { global } from '../../../services/global';

@Component({
    selector: 'app-sortides',
    templateUrl: './sortides.component.html',
    styleUrls: ['./sortides.component.scss']
})
export class SortidesComponent implements OnInit {
    public sortida: string | null;
    public error: string | null;

    constructor(
        private mercaderiesService: MercaderiesService,
        private userService: UserService,
        private router: Router
    ) {
    }

    ngOnInit(): void {

    }

    change(sortida): void {
        if (sortida.length === 6) {
            global.showPreloader = true;
            this.mercaderiesService.getSortida(this.userService.getToken(), sortida).subscribe(
                next => {
                    if (next.status === 'error') {
                        this.error = next.message;
                    } else {
                        this.mercaderiesService.sortida = next.sortida;
                        this.router.navigate(['/mercaderies/sortida', next.sortida.idSortida]);
                    }
                    global.showPreloader = false;
                },
                error => {
                    global.showPreloader = false;
                    if (error.status === 401) {
                        this.router.navigate(['/logout/1']);
                    } else {
                        this.error = error.error.message;
                    }
                }
            );
        } else {
            this.error = '';
        }

    }
}
